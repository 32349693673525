import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/MainHomePages/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "LandingPage" */
        "../views/MainHomePages/LandingPage.vue"
      ),
    children: [
      {
        name: "CXODashboard",
        path: "/CXODashboard",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/CXODashboard.vue"
          ),
      },
      {
        name: "Dashboard",
        path: "/Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Dashboard.vue"
          ),
      },
      {
        name: "DrVisitReport",
        path: "/DrVisitReport",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/DrVisitReport.vue"
          ),
      },

      {
        name: "VerifiedPov",
        path: "/VerifiedPov",
        component: () =>
          import(
            /* webpackChunkName: "Povlist" */
            "../views/ChildHomePages/VerifiedPov.vue"
          ),
      },
      {
        name: "UnverifiedPov",
        path: "/UnverifiedPov",
        component: () =>
          import(
            /* webpackChunkName: "Povlist" */
            "../views/ChildHomePages/UnverifiedPov.vue"
          ),
      },
      {
        name: "DraftPov",
        path: "/DraftPov",
        component: () =>
          import(
            /* webpackChunkName: "Povlist" */
            "../views/ChildHomePages/DraftPov.vue"
          ),
      },
      {
        name: "EditedPOVs",
        path: "/EditedPOVs",
        component: () =>
          import(
            /* webpackChunkName: "Povlist" */
            "../views/ChildHomePages/EditedPOVs.vue"
          ),
      },
      {
        name: "RejectedPOVs",
        path: "/RejectedPOVs",
        component: () =>
          import(
            /* webpackChunkName: "Povlist" */
            "../views/ChildHomePages/RejectedPOVs.vue"
          ),
      },
      {
        name: "Povlist",
        path: "/Povlist",
        component: () =>
          import(
            /* webpackChunkName: "Povlist" */
            "../views/ChildHomePages/Povlist.vue"
          ),
      },
      {
        name: "Drugslist",
        path: "/Drugslist",
        component: () =>
          import(
            /* webpackChunkName: "Drugslist" */
            "../views/ChildHomePages/Drugslist.vue"
          ),
      },

      {
        name: "WorkFlow",
        path: "/WorkFlow",
        component: () =>
          import(
            /* webpackChunkName: "Drugslist" */
            "../views/ChildHomePages/WorkFlow.vue"
          ),
      },
      {
        name: "Territories",
        path: "/Territories",
        component: () =>
          import(
            /* webpackChunkName: "Territories" */
            "../views/ChildHomePages/Territories.vue"
          ),
      },

      {
        name: "ReportsLog",
        path: "/ReportsLog",
        component: () =>
          import(
            /* webpackChunkName: "ReportsLog" */
            "../views/ChildHomePages/ReportExcel1.vue"
          ),
      },

      {
        name: "AuditLog",
        path: "/AuditLog",
        component: () =>
          import(
            /* webpackChunkName: "AuditLog" */
            "../views/ChildHomePages/AuditLog.vue"
          ),
      },
      {
        name: "Cabinet",
        path: "/Cabinet",
        component: () =>
          import(
            /* webpackChunkName: "Cabinet" */
            "../views/ChildHomePages/Cabinet.vue"
          ),
      },
      {
        name: "Users",
        path: "/Users",
        component: () =>
          import(
            /* webpackChunkName: "Users" */
            "../views/ChildHomePages/Users.vue"
          ),
      },
      {
        name: "MyReportee",
        path: "/MyReportee",
        component: () =>
          import(
            /* webpackChunkName: "Users" */
            "../views/ChildHomePages/MyReportee.vue"
          ),
      },
      {
        name: "SalesCapture",
        path: "/SalesCapture",
        component: () =>
          import(
            /* webpackChunkName: "Sales Capture" */
            "../views/ChildHomePages/SalesCapture.vue"
          ),
      },

      {
        name: "Container",
        path: "/Container",
        component: () =>
          import(
            /* webpackChunkName: "Container" */
            "../views/ChildHomePages/Container.vue"
          ),
      },

      {
        name: "Designation",
        path: "/Designation",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Designation.vue"
          ),
      },
      {
        name: "Division",
        path: "/Division",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Division.vue"
          ),
      },
      {
        name: "Specialization",
        path: "/Specialization",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Specialization.vue"
          ),
      },
      {
        name: "DoctorCategory",
        path: "/DoctorCategory",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/DoctorCategory.vue"
          ),
      },
      {
        name: "DrugType",
        path: "/DrugType",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/DrugType.vue"
          ),
      },
      {
        name: "Sample",
        path: "/Sample",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Sample.vue"
          ),
      },
      {
        name: "TerritoryCategory",
        path: "/TerritoryCategory",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/TerritoryCategory.vue"
          ),
      },
      {
        name: "CampaignTagging",
        path: "/CampaignTagging",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/CampaignTagging.vue"
          ),
      },
      {
        name: "LeaveBehind",
        path: "/LeaveBehind",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/LeaveBehind.vue"
          ),
      },

      {
        name: "CustomerJourney",
        path: "/CustomerJourney",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/CustomerJourney.vue"
          ),
      },
      {
        name: "CME",
        path: "/CME",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/CME.vue"
          ),
      },
      {
        name: "povRemarks",
        path: "/povRemarks",
        component: () => import("../views/ChildHomePages/povRemarks.vue"),
      },
      {
        name: "ScheduledCME",
        path: "/ScheduledCME",
        component: () => import("../views/ChildHomePages/ScheduledCME.vue"),
      },
      {
        name: "Notifications",
        path: "/Notifications",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Notifications.vue"
          ),
      },
      {
        name: "OrganizationSettings",
        path: "/OrganizationSettings",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationSettings" */
            "../views/ChildHomePages/OrganizationSettings.vue"
          ),
      },

      {
        name: "VisitReport",
        path: "/VisitReport",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/VisitReport.vue"
          ),
      },
      {
        name: "VisitReports",
        path: "/VisitReports",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/VisitReports.vue"
          ),
      },
      {
        name: "AllVisitReports",
        path: "/AllVisitReports",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/AllVisitReports.vue"
          ),
      },
      {
        name: "NonPovVisitReports",
        path: "/NonPovVisitReports",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/NonPovVisitReports.vue"
          ),
      },
      {
        name: "DailyReport",
        path: "/DailyReport",
        component: () =>
          import(
            /* webpackChunkName: "FeedBackReport" */
            "../views/ChildHomePages/Reports/DailyReport.vue"
          ),
      },
      {
        name: "FeedBackReport",
        path: "/FeedBackReport",
        component: () =>
          import(
            /* webpackChunkName: "FeedBackReport" */
            "../views/ChildHomePages/Reports/FeedBackReport.vue"
          ),
      },
      // {
      //   name: "Test",
      //   path: "/Test",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "FeedBackReport" */
      //       "../views/ChildHomePages/Reports/Test.vue"
      //     ),
      // },
      {
        name: "MonthlyDetailedReport",
        path: "/MonthlyDetailedReport",
        component: () =>
          import(
            /* webpackChunkName: "Sales Capture" */
            "../views/ChildHomePages/Reports/MonthlyDetailedReport.vue"
          ),
      },
      {
        name: "CMEReport",
        path: "/CMEReport",
        component: () =>
          import(
            /* webpackChunkName: "CMEReport" */
            "../views/ChildHomePages/Reports/CMEReport.vue"
          ),
      },
      // 22
      {
        name: "PMCReport",
        path: "/PMCReport",
        component: () =>
          import(
            /* webpackChunkName: "PMCReport" */
            "../views/ChildHomePages/Reports/PMCReport.vue"
          ),
      },

      {
        name: "TPDeviationReport",
        path: "/TPDeviationReport",
        component: () =>
          import(
            /* webpackChunkName: "TPDeviationReport" */
            "../views/ChildHomePages/Reports/TPDeviationReport.vue"
          ),
      },

      {
        name: "DoctorConversionReport",
        path: "/DoctorConversionReport",
        component: () =>
          import(
            /* webpackChunkName: "TPDeviationReport" */
            "../views/ChildHomePages/Reports/DoctorConversionReport.vue"
          ),
      },
      {
        name: "ExpenseReport",
        path: "/ExpenseReport",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/ExpenseReport.vue"
          ),
      },
      {
        name: "AttendReport",
        path: "/AttendReport",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/AttendReport.vue"
          ),
      },
      {
        name: "EDetailLogs",
        path: "/EDetailLogs",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/EDetailLogs.vue"
          ),
      },
      {
        name: "SalesReport",
        path: "/SalesReport",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/SalesReport.vue"
          ),
      },
      {
        name: "MarketingReport",
        path: "/MarketingReport",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/MarketingReport.vue"
          ),
      },
      {
        name: "UnverifiedPOVLogs",
        path: "/UnverifiedPOVLogs",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/UnverifiedPOVLogs.vue"
          ),
      },
      {
        name: "DashboardReports",
        path: "/DashboardReports",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/DashboardReports.vue"
          ),
      },
      {
        name: "InvalidUploads",
        path: "/InvalidUploads",
        component: () =>
          import(
            /* webpackChunkName: "DrVisitReport" */
            "../views/ChildHomePages/Reports/InvalidUploads.vue"
          ),
      },
      {
        name: "DoctorProfile",
        path: "/DoctorProfile",
        component: () =>
          import(
            /* webpackChunkName: "DoctorProfile" */
            "../views/ChildHomePages/DoctorProfile.vue"
          ),
      },
    ],
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: () => import("@/components/ReportIssue.vue"),
  },
];

const router = new VueRouter({
  // mode:'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
